<template>
  <div class="affixion-contain">
    <Form :label-width="115">
      <Row>
        <Col span="8" offset="8">
          <Form-item label="日期：">
            <Date-picker type="date" :options="options" v-model="test_date" placeholder="选择日期" @on-change="handleChangeDate"></Date-picker>
          </Form-item>
          <Form-item label="采集时间：">
            <Time-picker confirm type="time" placeholder="选择时间" v-model="test_time" @on-change="handleChangeTime"></Time-picker>
          </Form-item>
          <Form-item label="体重：">
            <Row>
              <Col :span="22"><Input v-model="bodyData.weight" placeholder="请输入体重"></Input></Col>
              <Col :span="2">&nbsp;&nbsp;kg</Col>
            </Row>
          </Form-item>
          <Form-item label="体格指数：">
            <Row>
              <Col :span="22"><Input v-model="bodyData.build" disabled placeholder="请输入体格指数"></Input></Col>
              <Col :span="2">&nbsp;&nbsp;</Col>
            </Row>
          </Form-item>
          <Form-item label="体脂肪率：">
            <Row>
              <Col :span="22"><Input v-model="bodyData.body_fat" placeholder="请输入体脂肪率"></Input></Col>
              <Col :span="2">&nbsp;&nbsp;%</Col>
            </Row>
          </Form-item>
          <Form-item label="内脂指数：">
            <Row>
              <Col :span="22"><Input v-model="bodyData.lactone" placeholder="请输入内脂指数"></Input></Col>
              <Col :span="2">&nbsp;&nbsp;</Col>
            </Row>
          </Form-item>
          <Form-item label="体水分率：">
            <Row>
              <Col :span="22"><Input v-model="bodyData.body_water" placeholder="请输入体水分率"></Input></Col>
              <Col :span="2">&nbsp;&nbsp;%</Col>
            </Row>
          </Form-item>
          <Form-item label="骨骼肌率：">
            <Row>
              <Col :span="22"><Input v-model="bodyData.muscull_skeleti" placeholder="请输入骨骼肌率"></Input></Col>
              <Col :span="2">&nbsp;&nbsp;%</Col>
            </Row>
          </Form-item>
          <Form-item label="骨矿含量：">
            <Row>
              <Col :span="22"><Input v-model="bodyData.bmc" placeholder="请输入骨矿含量"></Input></Col>
              <Col :span="2">&nbsp;&nbsp;kg</Col>
            </Row>
          </Form-item>
          <Form-item label="基础代谢：">
            <Row>
              <Col :span="22"><Input v-model="bodyData.basic" placeholder="请输入基础代谢"></Input></Col>
              <Col :span="2">&nbsp;&nbsp;kcal</Col>
            </Row>
          </Form-item>
        </Col>
      </Row>
      <div class="symbol-box tac">
        <Button type="primary" size="large" @click="handleBack">返回</Button>
        <Button type="success" size="large" @click="handleSave" v-show="power.indexOf('会员管理--编辑会员信息权限') > -1">保存</Button>
      </div>
    </Form>
  </div>
</template>
<script type="text/ecmascript-6">
import MemberService from '@/services/memberService';
export default {
	data() {
		return {
			bodyData: {
				test_date: '',
				test_time: '',
				weight: '',
				build: '',
				body_fat: '',
				lactone: '',
				body_water: '',
				muscull_skeleti: '',
				bmc: '',
				basic: '',
			},
			test_date: '',
			test_time: '',
			power: [],
			options: {
				disabledDate(date) {
					return date && date.valueOf() >= Date.now();
				},
			},
		};
	},
	computed: {
		bodyId() {
			return this.$route.params.bodyId;
		},
		userInfo() {
			return this.$store.getters.userInfo;
		},
	},
	methods: {
		handleInit() {
			let arr = [];
			MemberService.bodyDetail({ id: this.bodyId }).then((data) => {
				arr = data.create_time.split(' ');
				this.test_date = this.bodyData.test_date = arr[0];
				this.test_time = this.bodyData.test_time = arr[1];
				this.bodyData.weight = data.weight === 0 ? '' : data.weight;
				this.bodyData.build = data.build === 0 ? '' : data.build;
				this.bodyData.body_fat = data.body_fat === 0 ? '' : data.body_fat;
				this.bodyData.lactone = data.lactone === 0 ? '' : data.lactone;
				this.bodyData.body_water = data.body_water === 0 ? '' : data.body_water;
				this.bodyData.muscull_skeleti = data.musculi_skeleti === 0 ? '' : data.musculi_skeleti;
				this.bodyData.bmc = data.bmc === 0 ? '' : data.bmc;
				this.bodyData.basic = data.basic === 0 ? '' : data.basic;
			});
		},
		handleBack() {
			this.$router.go(-1);
		},
		handleSave() {
			if (this.validateFormData()) {
				this.bodyData.id = this.bodyId;
				MemberService.bodyUpdate(this.bodyData).then(() => {
					this.$router.go(-1);
				});
			}
		},
		handleChangeDate(value) {
			this.bodyData.test_date = value;
		},
		handleChangeTime(value) {
			this.bodyData.test_time = value;
		},
		isNumber(arg) {
			return typeof parseFloat(arg) === 'number';
		},
		isPositiveNumber(value) {
			return this.isNumber(value) && value >= 0;
		},
		validateFormData() {
			if (!this.test_date) {
				this.$Message.warning('请选择日期');
				return false;
			} else if (!this.test_time) {
				this.$Message.warning('请选择采集时间');
				return false;
			} else if (this.bodyData.weight === '') {
				this.$Message.warning('请输入体重');
				return false;
			} else if (!this.isPositiveNumber(this.bodyData.weight)) {
				this.$Message.warning('请输入体重有效值');
				return false;
			} else if (this.bodyData.body_fat === '') {
				this.$Message.warning('请输入体脂肪率');
				return false;
			} else if (!this.isPositiveNumber(this.bodyData.body_fat)) {
				this.$Message.warning('请输入体脂肪率有效值');
				return false;
			} else if (this.bodyData.lactone === '') {
				this.$Message.warning('请输入内脂指数');
				return false;
			} else if (!this.isPositiveNumber(this.bodyData.lactone)) {
				this.$Message.warning('请输入内脂指数有效值');
				return false;
			} else if (this.bodyData.body_water === '') {
				this.$Message.warning('请输入体水分率');
				return false;
			} else if (!this.isPositiveNumber(this.bodyData.body_water)) {
				this.$Message.warning('请输入体水分率有效值');
				return false;
			} else if (this.bodyData.muscull_skeleti === '') {
				this.$Message.warning('请输入骨骼肌率');
				return false;
			} else if (!this.isPositiveNumber(this.bodyData.muscull_skeleti)) {
				this.$Message.warning('请输入骨骼肌率有效值');
				return false;
			} else if (this.bodyData.bmc === '') {
				this.$Message.warning('请输入骨矿含量');
				return false;
			} else if (!this.isPositiveNumber(this.bodyData.bmc)) {
				this.$Message.warning('请输入骨矿含量有效值');
				return false;
			} else if (this.bodyData.basic === '') {
				this.$Message.warning('请输入基础代谢');
				return false;
			} else if (!this.isPositiveNumber(this.bodyData.basic)) {
				this.$Message.warning('请输入基础代谢有效值');
				return false;
			} else {
				return true;
			}
		},
	},
	mounted() {
		this.power = this.userInfo.power;
		this.handleInit();
	},
};
</script>
<style lang="css" scoped>
.affixion-contain {
  margin-top: 40px;
}
</style>
